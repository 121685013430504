import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

const getMetaContent = (name) => {
  const $meta = document.querySelector(`meta[name=${name}]`);
  return $meta ? $meta.getAttribute("content") : null;
};

const SENTRY_DSN = getMetaContent("sentry-dsn");
const SENTRY_ENV = getMetaContent("sentry-env");
const CURRENT_USER_ID = getMetaContent("sentry-user");

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Integrations.Dedupe(),
    new Integrations.ExtraErrorData({ depth: 10 }),
    new Integrations.CaptureConsole({ levels: ["error", "warn", "info"] }),
  ],
  normalizeDepth: 10,
});

if (SENTRY_ENV) {
  Sentry.configureScope((scope) => {
    scope.setTag("environment", SENTRY_ENV);
  });
}

if (CURRENT_USER_ID) {
  Sentry.configureScope((scope) => {
    scope.setUser({ id: CURRENT_USER_ID });
  });
}
